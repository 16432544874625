const getValueFromField = (obj, field) => {
  const keys = field.split('.');
  const key = keys.shift();
  if (obj && obj[key] !== undefined) {
    if (keys.length === 0) {
      return obj[key];
    }
    return getValueFromField(obj[key], keys.join('.'));
  }
  return undefined;
};

const progressFields = [
  'photoURL',
  'displayName',
  'displaySurname',
  'teacher.teachingStyle',
  'teacher.startingTeaching',
  'teacher.about',
  'teacher.pricePerLesson.max',
  'teacher.pricePerLesson.min',
  'teacher.school.schoolDescription',
  'teacher.experience.length',
  'teacher.qualification.affiliations.length',
  'teacher.qualification.associations.length',
  'teacher.qualification.development.length',
  'teacher.teacherPreferences.length',
  'socialLinks.length',
];

export function getProgressForTeacherProfile(user) {
  const progressArray = progressFields.map((field) => {
    return getValueFromField(user, field) ? 1 : 0;
  });

  const progress = (progressArray.reduce((acc, val) => acc + val, 0) / progressArray.length) * 100;

  return Math.min(100, progress * 1.4);
}

export function validateTeacherProfile(user) {
  return getProgressForTeacherProfile(user) === 100;
}
