import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Switch, useLocation } from 'react-router-dom';
import HomePage from 'containers/HomePage';
import LandingPage from 'containers/LandingPage';
import PrivacyPage from 'containers/PrivacyPage';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import SignInPage from 'containers/SignIn/Loadable';
import PasswordResetPage from 'containers/PasswordReset';
import SignUpPage from 'containers/SignUp/Loadable';
import ProfilePage from 'containers/ProfilePage';
import TeachersPage from 'containers/TeachersPage';
import RoomPage from 'containers/RoomPage';
import AdminPage from 'admin/containers/AdminPage';
import VonagePage from 'containers/VideoPage';
import DashboardPage from 'containers/DashboardPage';
import { PageWrapper } from 'components/PageWrapper';
import { Header } from 'components/Header';
import routeNames from 'route-names';
import styled from 'styled-components';
import SuccessLessonRequestPage from 'containers/SuccessLessonRequestPage';
import AdminStat from 'admin/containers/AdminStat';
import ContactPage from 'containers/ContactPage';
import AdminSignals from 'admin/containers/AdminSignals';
import AdminSignalStat from 'admin/containers/AdminSignalStat';
import FreeVideoPage from 'containers/FreeVideoPage';
import JoinPage from 'containers/JoinPage';
import { useQuery } from 'hooks/useQuery';
import { setStringToLocalStorage } from 'services/localStorage';
import WrongRoom from 'containers/WrongRoom';
import ChatPage from 'containers/ChatPage';

import SettingsProfilePage from 'containers/SettingsProfilePage';

import { StudentProfilePage } from 'containers/UserProfilePage/StudentProfilePage';
import TeacherProfilePage from 'containers/UserProfilePage/TeacherProfilePage';
import TeacherCatalog from 'containers/TeacherCatalog';
import { useAuth } from 'hooks/useAuth';
import { User } from 'models/User.Class';
import { saveUser } from 'firebase-db/UserService';
import { validateTeacherProfile } from 'containers/SettingsProfilePage/services/validateTeacherProfile';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
`;

function App() {
  const { ref, invite, tab } = useQuery();
  const auth = useAuth();
  const user: User = auth.user.model;
  const savedOnce = useRef(false);

  useEffect(() => {
    if (!user || savedOnce.current) return;
    const isValidProfile = validateTeacherProfile(user);
    if (isValidProfile) {
      const newValues = {
        ...user.getData(),
        teacher: { ...user.getData().teacher, visibleForSearch: true },
      };

      saveUser(newValues, { merge: false })
        .then(() => {
          savedOnce.current = true;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [user]);

  useEffect(() => {
    if (ref) {
      setStringToLocalStorage('ref', ref);
    }
    if (invite) {
      setStringToLocalStorage('invite', invite);
    }
  }, [ref]);

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase().trim();
    // Facebook                                          // Instagram                // LINE
    if (ua.includes('fbios') || ua.includes('fb_iab') || ua.includes('instagram') || ua.includes(' line/')) {
      window.open(window.location.href, '_system');
    }
  });

  return (
    <AppWrapper>
      <ToastContainer autoClose={1500} />
      <Header />
      <PageWrapper>
        <Switch>
          <Route exact path={routeNames.home} component={HomePage} />
          <Route exact path={routeNames.landing} component={LandingPage} />
          <Route exact path={routeNames.privacy} component={PrivacyPage} />
          {/* <Route path={routeNames.settings.path} component={Settings} /> */}
          <Route path={routeNames.teacherProfile + '/:userId'} component={TeacherProfilePage} />
          <Route path={routeNames.studentProfile + '/:userId'} component={StudentProfilePage} />
          <Route exact path={routeNames.teachers} component={TeachersPage} />
          <Route exact path={routeNames.profile} component={ProfilePage} />
          <Route exact path={routeNames.contact} component={ContactPage} />
          <Route exact path={routeNames.signIn} component={SignInPage} />
          <Route exact path={routeNames.resetPassword} component={PasswordResetPage} />
          <Route exact path={routeNames.settingsProfile} component={SettingsProfilePage} />
          <Route exact path={routeNames.signUp} component={SignUpPage} />
          <Route exact path={routeNames.chat} component={ChatPage} />
          <Route exact path={routeNames.chatRoom} component={ChatPage} />
          <Route exact path={routeNames.successLessonRequestPage} component={SuccessLessonRequestPage} />
          <Route exact path={routeNames.lesson} component={RoomPage} />
          <Route exact path={routeNames.lesson + '/:roomId'} component={VonagePage} />
          <Route exact path={routeNames.room + '/:roomId'} component={FreeVideoPage} />
          <Route exact path={routeNames.wrongRoom} component={WrongRoom} />
          <Route exact path={routeNames.join + '/:roomId'} component={JoinPage} />
          <Route exact path={'/teachersearch'} component={TeacherCatalog} />
          <Route exact path={routeNames.admin} component={AdminPage} />
          <Route exact path={routeNames.stat} component={AdminStat} />
          <Route exact path={routeNames.adminSignals} component={AdminSignals} />
          <Route exact path={routeNames.adminSignalStat} component={AdminSignalStat} />
          <Route path="" component={NotFoundPage} />
        </Switch>
      </PageWrapper>
    </AppWrapper>
  );
}

export default App;
