import React, { useState } from 'react';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import { NotesModal } from './NotesModal';

export function ProfileInfo({
  photo,
  name,
  description,
  languages,
  isOwner,
  genderAge,
  surname,
}: {
  photo: string;
  name: string;
  description?: string;
  languages?: string[];
  isOwner: boolean;
  genderAge?: string;
  surname: string;
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <ProfilePageComponentWrapper>
      <Box display="flex" flexDirection="column" sx={{ gap: { xs: '16px', md: '24px' } }}>
        <Box>
          {photo ? (
            <img
              src={photo}
              alt="avatar"
              style={{
                borderRadius: '8px',
                width: '100%',

                maxHeight: '250px',
                objectFit: 'cover',
              }}
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '250px',
                bgcolor: '#EAEAEA',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Avatar />
            </Box>
          )}
        </Box>

        <Box display="flex" flexDirection="column" gap="12px">
          <Typography
            variant="h2"
            sx={{ color: '#101828', fontSize: '1.5rem', fontWeight: 700, lineHeight: 1.36, wordBreak: 'normal' }}>
            {name} {surname}
          </Typography>
          {description&&<Typography
            variant="subtitle1"
            sx={{ color: '#667085', fontSize: '0.875rem', fontWeight: 500, lineHeight: 1.5 }}>
            {description}
          </Typography>}
          {genderAge && (
            <Typography
              variant="subtitle1"
              sx={{ color: '#667085', fontSize: '0.875rem', fontWeight: 500, lineHeight: 1.5 }}>
              {genderAge}
            </Typography>
          )}
        </Box>
        {languages?.[0] && (
          <Box display="flex" flexDirection="column" gap="12px">
            <Typography variant="h3" sx={{ color: '#101828', fontSize: '1.125rem', fontWeight: 600, lineHeight: 1.36 }}>
              Spoken Languages
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: '#667085', fontSize: '0.875rem', fontWeight: 500, lineHeight: 1.5 }}>
              {languages?.join(', ')}
            </Typography>
          </Box>
        )}
        {/* {isOwner ? (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '2fr 1fr',
              gap: '16px',
            }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#452D9E',
                padding: '12px',
                borderRadius: '8px',
                textTransform: 'capitalize',
              }}>
              Book A Lesson
            </Button>
            <Button
              variant="outlined"
              sx={{
                boxShadow: '0px 1px 2px 0px #1018280D',
                border: '1px solid #D0D5DD',
                color: '#101828',
                padding: '12px',
                borderRadius: '8px',
                textTransform: 'capitalize',
              }}>
              Share
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '2fr 1fr',
              gap: '16px',
            }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#452D9E',
                padding: '12px',
                borderRadius: '8px',
                textTransform: 'capitalize',
              }}
              onClick={handleClickOpen}>
              Write a note
            </Button>
            <Button
              variant="outlined"
              sx={{
                boxShadow: '0px 1px 2px 0px #1018280D',
                border: '1px solid #D0D5DD',
                color: '#101828',
                padding: '12px',
                borderRadius: '8px',
                textTransform: 'capitalize',
              }}>
              Share
            </Button>
          </Box>
        )} */}
      </Box>
      <NotesModal open={open} setOpen={setOpen}></NotesModal>
    </ProfilePageComponentWrapper>
  );
}
